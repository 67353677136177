body {
  font-family: "Space Mono", monospace;
}

h1 {
  margin: 0;
  padding: 0;
  font-size: 6vw;
  font-weight: bold;
}

h2 {
  margin: 0;
  padding: 0;
  font-size: 3vw;
  font-weight: bold;
}

h3 {
  font-size: 24px;
  font-weight: bold;
}

h4 {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}

p {
  font-size: 16px;
  font-weight: 400;
}

#canvas {
  height: 100vh;
  width: 100vw;
}

#arrow {
  width: 10px;
  height: 10px;
  display: inline-block;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
